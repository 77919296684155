import React, { useState, useEffect } from 'react';
import './HeroSection.css';
import video from '../assets/check.mp4'; // Adjust the path if necessary

function HeroSection() {
  const messages = [
    "CHECK ON YOUR FRIENDS",
    "SPREAD LOVE",
    "OFFER A HELPING HAND",
    "LISTEN WITHOUT JUDGEMENT",
    "REMIND SOMEONE THEY MATTER",
    "BE THERE FOR SOMEONE",
    "SHOW COMPASSION"
  ];

  const [currentMessage, setCurrentMessage] = useState(0);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setCurrentMessage((prevMessage) => (prevMessage + 1) % messages.length);
    }, 5000); // Display each message for 5 seconds

    return () => clearTimeout(timeout);
  }, [currentMessage, messages.length]);

  return (
    <section className="hero">
      <video className="video-background" autoPlay loop muted playsInline>
        <source src={video} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="hero-content">
        <h1 key={currentMessage}>{messages[currentMessage]}</h1>
        <p>Because life is precious.</p>
      </div>
    </section>
  );
}

export default HeroSection;
