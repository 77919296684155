import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <footer className="footer">
      <p>Resources for Help and Support:</p>
      <ul>
        <li><a href="https://www.samaritans.org/" target="_blank" rel="noopener noreferrer">Samaritans</a></li>
        <li><a href="https://www.mind.org.uk/" target="_blank" rel="noopener noreferrer">Mind</a></li>
        <li><a href="https://www.rethink.org/" target="_blank" rel="noopener noreferrer">Rethink Mental Illness</a></li>
        <li><a href="https://www.youngminds.org.uk/" target="_blank" rel="noopener noreferrer">YoungMinds</a></li>
        <li><a href="https://www.papyrus-uk.org/" target="_blank" rel="noopener noreferrer">PAPYRUS</a></li>
      </ul>
      <p>&copy; 2024 LifeIsPrecious.uk | sam</p>
    </footer>
  );
}

export default Footer;
