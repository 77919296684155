import React from 'react';
import './ExplanationSection.css';

function ExplanationSection() {
  return (
    <section className="explanation">
      <p>
        LifeIsPrecious.uk is dedicated to raising awareness about mental health and suicide prevention. 
        We encourage everyone to check in on their loved ones and offer support to those who need it most.
        Every small action can make a significant difference because <b>life is precious.</b>
      </p>
      <p>
        It's easy to start spreading awareness:
      </p>
      <div className="steps">
        <div className="step">
          <h3>Step 1:</h3>
          <p>Attach the link <strong>LifeIsPrecious.uk</strong> to your social media post or story.</p>
        </div>
        <div className="step">
          <h3>Step 2:</h3>
          <p>Awareness spread! You've helped make a difference.</p>
        </div>
      </div>
      <a href="https://www.samaritans.org/" target="_blank" rel="noopener noreferrer" className="donation-link">
        Donate to Support Suicide Prevention
      </a>
    </section>
  );
}

export default ExplanationSection;
