import React from 'react';
import './ScrollingStatements.css';

function ScrollingStatements() {
  const statements = [
    'REACH OUT TO FAMILY: Because life is precious.',
    'LISTEN WITHOUT JUDGEMENT: Because life is precious.',
    'SHARE A SMILE TODAY: Because life is precious.',
    'SEND A KIND MESSAGE: Because life is precious.',
    'MAKE TIME FOR A CALL: Because life is precious.',
    'REMIND SOMEONE THEY MATTER: Because life is precious.',
    'OFFER A HELPING HAND: Because life is precious.',
    'SHOW COMPASSION: Because life is precious.',
    'BE THERE FOR SOMEONE: Because life is precious.',
    'SPREAD LOVE: Because life is precious.'
  ];

  const links = [
    'https://www.mind.org.uk/',
    'https://www.rethink.org/',
    'https://www.samaritans.org/',
    'https://www.youngminds.org.uk/',
    'https://www.mentalhealth.org.uk/',
    'https://www.sane.org.uk/',
    'https://www.papyrus-uk.org/',
    'https://www.mind.org.uk/information-support/types-of-mental-health-problems/suicidal-feelings/',
    'https://www.samaritans.org/how-we-can-help/contact-samaritan/',
    'https://www.time-to-change.org.uk/'
  ];

  return (
    <section className="statements">
      {statements.map((statement, index) => (
        <div key={index} className="statement">
          <h2>
            <a href={links[index]} target="_blank" rel="noopener noreferrer">
              {statement}
            </a>
          </h2>
        </div>
      ))}
    </section>
  );
}

export default ScrollingStatements;
