import React from 'react';
import HeroSection from './components/HeroSection';
import ExplanationSection from './components/ExplanationSection';
import ScrollingStatements from './components/ScrollingStatements';
import Footer from './components/Footer';
import './App.css';

function App() {
  return (
    <div className="App">
      <HeroSection />
      <ExplanationSection />
      <ScrollingStatements />
      <Footer />
    </div>
  );
}

export default App;
